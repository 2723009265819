<template>
    <div class="QingContainer" v-loading="loading">
        <div class="bread">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item class="p_bread"  :to="{ path: '/products/productsCenter', query:{categoryId:'68'} }"><a>产品中心</a></el-breadcrumb-item>
                <el-breadcrumb-item class="p_bread" :to="{ path: '/products/productsCenter', query:{categoryId:'5'} }"><a>MCU</a></el-breadcrumb-item>
                <el-breadcrumb-item>关于青稞内核</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="title" v-show="this.categoryId!=77">{{content.shortTitle}}</div>
        <hr class="hrClass" v-show="this.categoryId!=77">
        
        <div  v-if="content.contentType&&content.contentType=='markdown'" :class="this.categoryId==77?'overviewContent moduleContent':'detail_overviewContent moduleContent'">
           <v-md-editor v-model="content.content"  mode="preview" />
        </div>
        <div v-else :class="this.categoryId==77?'overviewContent moduleContent':'detail_overviewContent moduleContent'"  v-html="content.content"></div>
        
        
        <div class="buttonDiv" v-show="contentExist">
            <el-button @click="getMoreDetail()" type="primary" class="btn-danger">更多资料下载</el-button>
            <el-button @click="getMoreDemo()" type="primary" class="btn-primary">样品获取</el-button>
            <el-button @click="getContact()" class="btn-info">联系我们</el-button>
        </div>
        <div v-if="filesList.length > 0">
            <div class="moduleTitle">相关资料</div>
            <el-row :gutter="0" style="width:100%" class="table_1">
              <el-col :xs="8" :sm="7" :md="6" :lg="5" :xl="4" class="tableTitle">资料名称</el-col>
              <el-col :xs="16" :sm="17" :md="18" :lg="19" :xl="20"  class="tableContent">资料简介</el-col>
            </el-row>
            <div v-for="(item,index) in filesList" :key="index" class="table_2">
                <el-row :gutter="0" style="width:100%" >
                    <el-col :xs="8" :sm="7" :md="6" :lg="5" :xl="4" v-if="item" class="tableTitle">
                        <a class="tableTitle" :href="getLickStr(item.name)" target="_blank">{{ item.name }}</a>
                    </el-col>
                    <el-col :xs="16" :sm="17" :md="18" :lg="19" :xl="20"  v-if="item" class="tableContent">{{ item.content }}</el-col>
                </el-row>
            </div>
        </div>
        
      
        <!-- 产品没有详情页 -->
        <div v-show="!contentExist" style="display: flex; justify-content: center; min-height:600px; align-items: center">
            <img src="@/assets/image/noData.png" alt="">
        </div>
    </div>
</template>
<script>
    import { getArticle } from '@/services/product.js'
    export default {
    name: "Qinke",
    components: {
    },
    data() {
        return {
            content:'',
            filesList:'',
            url:{
                contentUrl:'/api/official/website/articles/getArticle'
            },
            loading: false,
            categoryId:'',
            contentExist:true,//存在文章

            partnerList: [1,2],
            currentIndex: 0,
            windowWidth:window.innerWidth,
            isMoPhone:false,
        };
    },
    computed: {
    },
    watch: {
        windowWidth(newWidth, oldWidth) {
            if (newWidth <= 768) {
                this.isMoPhone = true
            }else{
                this.isMoPhone = false
            }
        },
    },
   
    mounted() {
        if (this.windowWidth <= 768) {
            this.isMoPhone = true
        }else{
            this.isMoPhone = false
        }
    },
    activated(){
        if(this.$route.query.categoryId){
            this.categoryId = this.$route.query.categoryId
            this.$emit('giveCategoryId',this.categoryId)//将当前激活侧边菜单传递给父组件
        }
        this.getContent_1()
    },
    methods:{
        //获取页面内容
        getContent_1(){
            this.loading = true;
            getArticle({alias:'QingKe.html'}).then(res => {
                if(res.data.code===0 && res.data){
                    this.content = res.data.data
                    this.content.shortTitle = this.content.shortTitle.replace(/:/g,' ')
                    this.filesList = this.content.filesList

                    document.title = this.content.shortTitle+window._CONFIG['pageTitle']
                    this.contentExist = true
                }else{
                    this.contentExist = false
                }
                this.loading = false;
            })
        },
        clickToTab(str){
            str = str.replace(".", "_")
            window.open('https://www.wch.cn/downloads/'+str+'.html')
        },
        getLickStr(str){
            str = str.replace(".", "_")
            return 'https://www.wch.cn/downloads/'+str+'.html'
        },
        getMoreDetail(){
            window.open('https://www.wch.cn/search.html?q='+this.content.alias+'&t=downloads', '_self') 
        },
        getMoreDemo(){
            window.open('https://www.wch.cn/services/request_sample.html?product_name='+this.content.alias, '_self')
        },
        getContact(){
            window.open('https://www.wch.cn/contact_us.html')
        },

    }
};
</script>
<style scoped >
.hrClass{
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #cbd0d4;
}
.detail_overviewContent{
    font-size: 15px;
}
.detail_overviewContent /deep/a{
    color: #0070c0 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/span{
    font-size: 15px !important;
    /* color: #000 !important; */
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ h4 span {
    font-size: 20px !important;
    font-weight: 600;
    color: #002c85 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ h5 span, 
.detail_overviewContent /deep/ h5  {
    font-weight: 700;
    color: #00B0F0 !important;
    padding-left: 5px;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    margin: 0px;
}
.detail_overviewContent /deep/ span a {
    font-size: 15px !important;
}
.detail_overviewContent /deep/ p span {
    font-size: 15px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ h5 span {
    /* font-size: 15px !important;
    font-weight: 600;
    color: #002c85 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important; */
}
.detail_overviewContent /deep/ ul li span {
    font-size: 15px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ ol li span {
    font-size: 15px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ ol li a span {
    font-size: 15px !important;
    color: #0070c0 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ul,
.detail_overviewContent /deep/ol
{
    margin: 0px !important;
}
.detail_overviewContent /deep/img {
    max-width: 100% !important;
    height: auto !important;
}
.detail_overviewContent /deep/ h4{
    font-size: 20px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
}




.overviewContent{
    font-size: 16px;
}
.overviewContent /deep/a{
    color: #0070c0 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/span{
    font-size: 16px !important;
    /* color: #000 !important; */
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ h4 span {
    font-size: 22px !important;
    font-weight: 600;
    color: #002c85 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ h5 span, 
.overviewContent /deep/ h5  {
    font-weight: 700;
    color: #00B0F0 !important;
    padding-left: 5px;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    margin: 0px;
}
.overviewContent /deep/ span a {
    font-size: 16px !important;
}
.overviewContent /deep/ p span {
    font-size: 16px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ h5 span {
    font-size: 16px !important;
    font-weight: 600;
    color: #002c85 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ ul li span {
    font-size: 16px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ ol li span {
    font-size: 16px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ ol li a span {
    font-size: 16px !important;
    color: #0070c0 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ul,
.overviewContent /deep/ol
{
    margin: 0px !important;
}
.overviewContent .dealImg {
    height: 713px;
    width: 1200px;
    max-width: 100% !important;
    height: auto !important;
}
.overviewContent /deep/ h4{
    font-size: 22px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.moduleContent{
    line-height: 1.5;
}
.moduleContent /deep/.github-markdown-body{
    padding: 0px;
}
.moduleContent /deep/ img{
    margin: 0 auto;
    display: flex;
    width: 100% !important;
    height: 100% !important;
}
.QingContainer{
    width: 100%;
    text-align: left;
}
.title{
    font-size: 28px;
    color: #002c85;
    font-weight: 600;
    margin: 15px 0px;
    letter-spacing: 0px;
}
.p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
}
.lineupContent{
    display: flex;
    justify-content: center;
}

.moduleContent{
    color:#000;
    letter-spacing: 0px;
    margin-bottom:50px;
}
.moduleTitle{
    font-size: 22px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 20px;
 }
.table_1 {
    font-weight: 600;
    border-bottom: 2px solid #ccc;
}
.table_2 {
    border-bottom: 1px solid #ccc;
}
.table_1 .tableTitle{
    padding: 12px;
}
.table_1 .tableContent{
    padding: 12px;
}
.table_2 .tableTitle{
    padding-top: 8px;
    color: #337BC5 !important;
    cursor: pointer;
    word-break: break-all;
    text-decoration:none;
}
.table_2 .tableContent{
    padding: 8px;
}
.buttonDiv{
    display: flex;
    justify-content: center;
}

.btn-danger{
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}
.btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
}
.btn-primary{
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}
.btn-primary:hover {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
}
.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
}
.btn-info:hover {
    color: #fff;
    background-color: #269abc;
    border-color: #269abc;
}
.el-breadcrumb__item{
    margin-bottom: 5px;
}
.lineupContainer{
    width: 100%;
    /* border: 1px solid #ccc; */
    /* background: url('../../../assets/image/qingkeBg.png') no-repeat;
    background-size: contain; */
    position: relative;
    /* height: 500px; */
}
.lineupBg{
    width: 100%;
}
.lineupBg img{
    width: 100% !important;
    height: 523px; /*背景图定高*/
}

.imgWrapper{
    position: absolute;
    z-index: 9;
    top: 8.4%;
    left: 7.1%;
    width: 92.9%;
}
.box {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    /* 定高 */
    height: 447px;
}
.imgList {
    animation:rolling 32s linear infinite;
    position: absolute;
    display: flex;
    position: relative;
    /* animation-play-state: paused;  */
}
.imgList:hover{
    cursor: pointer;
    animation-play-state: paused; 
}
.imgList img{
    width: 150% !important;
    height:447px !important;
}

@keyframes rolling {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-150%);
    }
}
/* 小屏兼容 */
@media screen and (max-width: 1600px) {
   .lineupBg img{
        height: 467px; /*背景图定高*/
    }
    .box {
        height: 400px; /* 定高 */
    }
    .imgList img{
        height:400px !important;
    }
}
@media screen and (max-width: 1400px) {
   .lineupBg img{
        height: 420px; /*背景图定高*/
    }
    .box {
        height: 360px; /* 定高 */
    }
    .imgList img{
        height:360px !important;
    }
}
@media screen and (max-width: 1200px) {
   .lineupBg img{
        height: 360px; /*背景图定高*/
    }
    .box {
        height: 309px; /* 定高 */
    }
    .imgList img{
        height:309px !important;
    }
}
@media screen and (max-width: 1000px) {
   .lineupBg img{
        height: 300px; /*背景图定高*/
    }
    .box {
        height: 257px; /* 定高 */
    }
    .imgList img{
        height:257px !important;
    }
}
@media screen and (max-width: 850px) {
   .lineupBg img{
        height: 245px; /*背景图定高*/
    }
    .box {
        height: 210px; /* 定高 */
    }
    .imgList img{
        height:210px !important;
    }
}
@media screen and (max-width: 688px) {
    .btn-danger {
        padding: 12px 10px;
    }
    .btn-primary {
        padding: 12px 10px;
    }
    .btn-info {
        padding: 12px 10px;
    }
}

</style>

<style scoped lang="less">
@unit1_32V003_left_percent: 1.5%;
@unit1_641_left_percent: 9%;
@unit1_32V002_left_percent: 16.5%;
@unit1_32V006_left_percent: 24.1%;
@unit1_32V103_left_percent: 32%;
@unit1_573_left_percent: 39.5%;
@unit1_569_left_percent: 46.7%;
@unit1_585_left_percent: 54%;
@unit1_583_left_percent: 61.2%;
@unit1_32V203_left_percent: 68.5%;
@unit1_32V208_left_percent: 76.1%;
@unit1_32X035_left_percent: 83.6%;
@unit1_32L103_left_percent: 91.1%;
@unit1_592_left_percent: 98.7%;
@unit1_643_left_percent: 105.9%;
@unit1_645_left_percent: 113.1%;
@unit1_32V303_left_percent: 120.4%;
@unit1_32V307_left_percent: 127.9%;
@unit1_32V317_left_percent: 135.4%;
@unit1_564_left_percent: 143.2%;
@add_percent: 150%;
.unit{
    position: absolute;
    display: block;
    z-index: 6;
    // background: rgba(0, 0, 0,.3);
    border-radius: 5px;
}
.unit:hover{
    box-shadow: 7px 5px 10px rgba(46, 46, 46,0.4),0 0 10px rgba(0, 0, 0,0) inset;
    border: transparent;
    transition: all .3s;
}
/* 第一张图的块 */
.unit1_32V003{
    width: 6.5%;
    height: 18.6%;
    top: 66%;
    left: @unit1_32V003_left_percent;
}
.unit1_641{
    width: 6%;
    height: 40.7%;
    top: 44.5%;
    left: @unit1_641_left_percent;
}
.unit1_32V002{
    width: 6.5%;
    height: 18.6%;
    top: 66%;
    left: @unit1_32V002_left_percent;
}
.unit1_32V006{
    width: 6.5%;
    height: 18.6%;
    top: 66%;
    left: @unit1_32V006_left_percent;
}
.unit1_32V103{
    width: 6.4%;
    height: 40.7%;
    top: 44.5%;
    left: @unit1_32V103_left_percent;
}
.unit1_573{
    width: 6%;
    height: 84.4%;
    top: 0.6%;
    left:  @unit1_573_left_percent;
}
.unit1_569{
    width: 6%;
    height: 62.4%;
    top: 22.6%;
    left:  @unit1_569_left_percent;
}
.unit1_585{
    width: 6%;
    height: 84.4%;
    top: 0.6%;
    left:  @unit1_585_left_percent;
}
.unit1_583{
    width: 6%;
    height: 84.4%;
    top: 0.6%;
    left: @unit1_583_left_percent;
}
.unit1_32V203{
    width: 6.4%;
    height: 40.7%;
    top: 44.5%;
    left:  @unit1_32V203_left_percent;
}
.unit1_32V208{
    width: 6.5%;
    height: 84.4%;
    top: 0.6%;
    left:  @unit1_32V208_left_percent;
}
.unit1_32X035{
    width: 6.4%;
    height: 40.7%;
    top: 44.5%;
    left:  @unit1_32X035_left_percent;
}
.unit1_32L103{
    width: 6.4%;
    height: 40.7%;
    top: 44.5%;
    left:  @unit1_32L103_left_percent;
}
.unit1_592{
    width: 6%;
    height: 84.4%;
    top: 0.6%;
    left:  @unit1_592_left_percent;
}
.unit1_643{
    width: 6%;
    height: 40.7%;
    top: 44.2%;
    left: @unit1_643_left_percent;
}
.unit1_645{
    width: 6%;
    height: 62.7%;
    top: 22.2%;
    left: @unit1_645_left_percent;
}
.unit1_32V303{
    width: 6.4%;
    height: 40.7%;
    top: 44.2%;
    left: @unit1_32V303_left_percent;
}
.unit1_32V307{
    width: 6.4%;
    height: 62.7%;
    top: 22.2%;
    left: @unit1_32V307_left_percent;
}
.unit1_32V317{
    width: 6.4%;
    height: 62.7%;
    top: 22.2%;
    left: @unit1_32V317_left_percent;
}
.unit1_564{
    width: 6.1%;
    height: 62.7%;
    top: 22.2%;
    left: @unit1_564_left_percent;
}

/* 第二张图片的块 */
.unit2_32V003{
    top: 66%;
    left: @unit1_32V003_left_percent+@add_percent;
    width: 6.5%;
    height: 18.6%;
}
.unit2_641{
    width: 6%;
    height: 40.7%;
    top: 44.5%;
    left: @unit1_641_left_percent+@add_percent;
}
.unit2_32V002{
    width: 6.5%;
    height: 18.6%;
    top: 66%;
    left: @unit1_32V002_left_percent+@add_percent;
}
.unit2_32V006{
    width: 6.5%;
    height: 18.6%;
    top: 66%;
    left: @unit1_32V006_left_percent+@add_percent;
}
.unit2_32V103{
    width: 6.4%;
    height: 40.7%;
    top: 44.5%;
    left: @unit1_32V103_left_percent+@add_percent;
}
.unit2_573{
    width: 6%;
    height: 84.4%;
    top: 0.6%;
    left:  @unit1_573_left_percent+@add_percent;
}
.unit2_569{
    width: 6%;
    height: 62.4%;
    top: 22.6%;
    left:  @unit1_569_left_percent+@add_percent;
}
.unit2_585{
    width: 6%;
    height: 84.4%;
    top: 0.6%;
    left:  @unit1_585_left_percent+@add_percent;
}
.unit2_583{
    width: 6%;
    height: 84.4%;
    top: 0.6%;
    left: @unit1_583_left_percent+@add_percent;
}
.unit2_32V203{
    width: 6.4%;
    height: 40.7%;
    top: 44.5%;
    left:  @unit1_32V203_left_percent+@add_percent;
}
.unit2_32V208{
    width: 6.5%;
    height: 84.4%;
    top: 0.6%;
    left:  @unit1_32V208_left_percent+@add_percent;
}
.unit2_32X035{
    width: 6.4%;
    height: 40.7%;
    top: 44.5%;
    left:  @unit1_32X035_left_percent+@add_percent;
}
.unit2_32L103{
    width: 6.4%;
    height: 40.7%;
    top: 44.5%;
    left:  @unit1_32L103_left_percent+@add_percent;
}
.unit2_592{
    width: 6%;
    height: 84.4%;
    top: 0.6%;
    left:  @unit1_592_left_percent+@add_percent;
}
.unit2_643{
    width: 6%;
    height: 40.7%;
    top: 44.2%;
    left: @unit1_643_left_percent+@add_percent;
}
.unit2_645{
    width: 6%;
    height: 62.7%;
    top: 22.2%;
    left: @unit1_645_left_percent+@add_percent;
}
.unit2_32V303{
    width: 6.4%;
    height: 40.7%;
    top: 44.2%;
    left: @unit1_32V303_left_percent+@add_percent;
}
.unit2_32V307{
    width: 6.4%;
    height: 62.7%;
    top: 22.2%;
    left: @unit1_32V307_left_percent+@add_percent;
}
.unit2_32V317{
    width: 6.4%;
    height: 62.7%;
    top: 22.2%;
    left: @unit1_32V317_left_percent+@add_percent;
}
.unit2_564{
    width: 6.1%;
    height: 62.7%;
    top: 22.2%;
    left: @unit1_564_left_percent+@add_percent;
}

// 兼容大屏
@unit1_32V003_left_num: 20px;
@unit1_641_left_num: 123px;
@unit1_32V002_left_num: 224px;
@unit1_32V006_left_num: 328px;
@unit1_32V103_left_num: 434px;
@unit1_573_left_num: 538px;
@unit1_569_left_num: 636px;
@unit1_585_left_num: 735px;
@unit1_583_left_num: 833px;
@unit1_32V203_left_num: 929px;
@unit1_32V208_left_num: 1035px;
@unit1_32X035_left_num: 1136px;
@unit1_32L103_left_num: 1238px;
@unit1_592_left_num: 1343px;
@unit1_643_left_num: 1442px;
@unit1_645_left_num: 1539px;
@unit1_32V303_left_num: 1637px;
@unit1_32V307_left_num: 1738px;
@unit1_32V317_left_num: 1842px;
@unit1_564_left_num: 1948px;
@add_num: 2039px;
@media screen and (min-width: 1921px) {
    .unit1_32V003{
        top: 294px;
        left: @unit1_32V003_left_num;
        width: 88px;
        height: 83px;
    }
    .unit1_641{
        width: 82px;
        height: 182px;
        top: 199px;
        left: @unit1_641_left_num;
    }
    .unit1_32V002{
        top: 294px;
        left: @unit1_32V002_left_num;
        width: 88px;
        height: 83px;
    }
    .unit1_32V006{
        top: 294px;
        left: @unit1_32V006_left_num;
        width: 88px;
        height: 83px;
    }
    .unit1_32V103{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32V103_left_num;
    }
    .unit1_573{
        width: 80px;
        height: 375px;
        top: 4px;
        left: @unit1_573_left_num;
    }
    .unit1_569{
        width: 79px;
        height: 278px;
        top: 101px;
        left: @unit1_569_left_num;
    }
    .unit1_585{
        width: 80px;
        height: 375px;
        top: 4px;
        left: @unit1_585_left_num;
    }
    .unit1_583{
       width: 80px;
        height: 375px;
        top: 4px;
        left: @unit1_583_left_num;
    }
    .unit1_32V203{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32V203_left_num;
    }
    .unit1_32V208{
        width: 87px;
        height: 375px;
        top: 3px;
        left: @unit1_32V208_left_num;
    }
    .unit1_32X035{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32X035_left_num;
    }
    .unit1_32L103{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32L103_left_num;
    }
    .unit1_592{
        width: 80px;
        height: 375px;
        top: 3px;
        left: @unit1_592_left_num;
    }
    .unit1_643{
        width: 80px;
        height: 179px;
        top: 199px;
        left: @unit1_643_left_num;
    }
    .unit1_645{
        width: 80px;
        height: 279px;
        top: 99px;
        left: @unit1_645_left_num;
    }
    .unit1_32V303{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32V303_left_num;
    }
    .unit1_32V307{
        width: 88px;
        height: 279px;
        top: 99px;
        left: @unit1_32V307_left_num;
    }
    .unit1_32V317{
        width: 88px;
        height: 279px;
        top: 99px;
        left: @unit1_32V317_left_num;
    }
    .unit1_564{
        width: 80px;
        height: 279px;
        top: 99px;
        left: @unit1_564_left_num;
    }
    // 第二张图
    .unit2_32V003{
        top: 294px;
        left: @unit1_32V003_left_num + @add_num;
        width: 88px;
        height: 83px;
    }
    .unit2_641{
        width: 82px;
        height: 182px;
        top: 199px;
        left: @unit1_641_left_num + @add_num;
    }
    .unit2_32V002{
        top: 294px;
        left: @unit1_32V002_left_num + @add_num;
        width: 88px;
        height: 83px;
    }
    .unit2_32V006{
        top: 294px;
        left: @unit1_32V006_left_num + @add_num;
        width: 88px;
        height: 83px;
    }
    .unit2_32V103{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32V103_left_num + @add_num;
    }
    .unit2_573{
        width: 80px;
        height: 375px;
        top: 4px;
        left: @unit1_573_left_num + @add_num;
    }
    .unit2_569{
        width: 79px;
        height: 278px;
        top: 101px;
        left: @unit1_569_left_num + @add_num;
    }
    .unit2_585{
        width: 80px;
        height: 375px;
        top: 4px;
        left: @unit1_585_left_num + @add_num;
    }
    .unit2_583{
       width: 80px;
        height: 375px;
        top: 4px;
        left: @unit1_583_left_num + @add_num;
    }
    .unit2_32V203{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32V203_left_num + @add_num;
    }
    .unit2_32V208{
        width: 87px;
        height: 375px;
        top: 3px;
        left: @unit1_32V208_left_num + @add_num;
    }
    .unit2_32X035{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32X035_left_num + @add_num;
    }
    .unit2_32L103{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32L103_left_num + @add_num;
    }
    .unit2_592{
        width: 80px;
        height: 375px;
        top: 3px;
        left: @unit1_592_left_num + @add_num;
    }
    .unit2_643{
        width: 80px;
        height: 179px;
        top: 199px;
        left: @unit1_643_left_num + @add_num;
    }
    .unit2_645{
        width: 80px;
        height: 279px;
        top: 99px;
        left: @unit1_645_left_num + @add_num;
    }
    .unit2_32V303{
        width: 88px;
        height: 182px;
        top: 199px;
        left: @unit1_32V303_left_num + @add_num;
    }
    .unit2_32V307{
        width: 88px;
        height: 279px;
        top: 99px;
        left: @unit1_32V307_left_num + @add_num;
    }
    .unit2_32V317{
        width: 88px;
        height: 279px;
        top: 99px;
        left: @unit1_32V317_left_num + @add_num;
    }
    .unit2_564{
        width: 80px;
        height: 279px;
        top: 99px;
        left: @unit1_564_left_num + @add_num;
    }
}
</style>